import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Homepage from './Homepage';
import InfoPage from './InfoPage';
import RFMAPage from './RFMAPage';
import PrivacyPage from './PrivacyPage';

export default (props) => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/rfma" component={RFMAPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/info/:projectname" component={p => <InfoPage urlParams={p.match.params} />} />
        </Switch>
    </BrowserRouter>
);