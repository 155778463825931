import React, { Component } from "react";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  submit = () => {
    this.setState({loading: true})
    let messageString = `AFM.io contact form\nName: ${this.state.name}\n Email: ${this.state.email}\n Message: ${this.state.message}`;
    // deans email API
    fetch('https://6ho5lq4vg46jpvhglufih532yi0rhxtv.lambda-url.us-east-1.on.aws', {
      method: 'POST',
      body: JSON.stringify({
        message: messageString
      })
    })
    .then(result => result.json())
    .then(result => {
      this.setState({loading: false})
      alert('Message Sent!')
    })
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let { name, email, message, loading } = this.state;
    return (
      <section className="contact" id="contact">
        <div className="container">
          <div className="heading text-center">
            <h2>Let's Talk</h2>
            <div className="line" />
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="title">
                <h3>Contact Us :</h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
              </div>
              <div className="content">
                {/* Info-1 */}
                <div className="info d-flex align-items-start">
                  <i className="fa fa-phone" aria-hidden="true" />
                  <h4 className="d-inline-block">
                    PHONE :
                    <br />
                    <span>650-560-4AFM</span>
                  </h4>
                </div>
                {/* Info-2 */}
                <div className="info d-flex align-items-start">
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                  <h4 className="d-inline-block">
                    EMAIL :
                    <br />
                    <span>info@afm.io</span>
                  </h4>
                </div>
                {/* Info-3 */}
                <div className="info d-flex align-items-start">
                  <i className="fa fa-street-view" aria-hidden="true" />
                  <h4 className="d-inline-block">
                    ADDRESS :<br />
                    <span>5656 Isabelle Ave, Ste 9<br/>Port Orange, FL 32127</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div>
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      onChange={this.onChange}
                      value={name}
                      name="name"
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={this.onChange}
                      value={email}
                      name="email"
                    />
                  </div>
                  {/* <div className="col-sm-12">
              <input type="text" className="form-control" placeholder="Subject" />
            </div> */}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows={5}
                    id="comment"
                    placeholder="Message"
                    defaultValue={""}
                    onChange={this.onChange}
                    value={message}
                    name="message"
                  />
                </div>
                <button className="btn btn-block" onClick={this.submit}>
                  {loading ? '...' : 'Send'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = {};

export default Contact;
