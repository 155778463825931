import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "./assets/css/bootstrap.min.css";
// import "./assets/css/plugins/animate.css";
// import "./assets/css/plugins/slick.css";
// import "./assets/css/plugins/slick-theme.css";
// import "./assets/css/plugins/magnific-popup.css";
import "./assets/css/main.css";

// import "./assets/js/plugins/jquery-3.3.1.min.js";
// import "./assets/js/plugins/popper.min.js";
// import "./assets/js/bootstrap.min.js";
// import "./assets/js/plugins/slick.min.js";
// import "./assets/js/plugins/jquery.waypoints.min.js";
// import "./assets/js/plugins/jquery.counterup.min.js";
// import "./assets/js/plugins/wow.min.js";
// import "./assets/js/plugins/magnific-popup.min.js";
// import "./assets/js/main.js";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
