import React, { Component } from 'react';
import colors from '../../assets/colors';
import ImageSection from '../Homepage/ImageSection';


class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div style={styles.wrapper} class='container'>


      </div>
    );
  }
}

const styles = {
  wrapper: {
    // minHeight: '60vh',
    paddingTop: 30
    // backgroundColor: colors.main
  }
};

export default Hero;