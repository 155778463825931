import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: ''
    };
  }

  componentDidMount(){
    let year = new Date().getFullYear();
    this.setState({year});
  }

  render() {
    return (
      <footer className="footer">
        <div className="container text-center">
          <img src={require('../../assets/img/logo-white.png')} height='60' alt />
          <p>© {this.state.year} Active Facility Monitoring LLC</p>
        </div>
      </footer>
    );
  }
}

const styles = {};

export default Footer;
