import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Hero from "./Hero";
import ImageSection from "./ImageSection";
import GetStarted from "./GetStarted";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";
import { isMobile } from 'react-device-detect';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Hero />
        <ImageSection 
          title='Emergency Management'
          // header='HEADER'
          description={'We can detect urgent problems hours before building occupants do, and long after they\'ve gone home for the night. Reduce \
          your risk of facility downtime and be the hero to your occupants again and again. Get some sleep -  we have emergencies covered.'}
          image={require('../../assets/img/graphics/emergency_mgmt.png')}
          link=''
          linkText='Learn More'
          left
        />
        <ImageSection 
          title='Fix it Once and Done'
          // header='HEADER'
          description={'We work remotely with on site technicians to mutually diagnose, categorize, and fix problems using the best practices. \
          with our unbiased oversight, we\'ll get it fixed right, and on the first visit.'}
          image={require('../../assets/img/graphics/fix_it.png')}
          link=''
          linkText='Learn More'
          left={isMobile}
        />
        <ImageSection 
          title='Asset Management'
          // header='HEADER'
          description={'Our software keeps a log of all major events in the lifecycle of your equipment. Knowing an equipment’s history will keep you from fixing \
          the same problem over and over. Your staff and service providers may change, but the equipment log lasts forever.'}
          image={require('../../assets/img/graphics/asset_mgmt.png')}
          link=''
          linkText='Learn More'
          left
        />
        <ImageSection 
          title='Active Energy Management'
          // header='HEADER'
          description={'We believe Energy Management should be integral to Facility Management. With continuous \
          monitoring and centralized control, we ensure the most efficient energy management \strategies while maintaining comfort and convenience.'}
          image={require('../../assets/img/graphics/energy_mgmt.png')}
          link=''
          linkText='Learn More'
          left={isMobile}
        />
        <ImageSection 
          title='Equipment Automation and Control'
          // header='HEADER'
          description={'We eliminate service calls caused by improper equipment configuration or abuse. \
          Did you know an improperly configured thermostat can cost hundreds or thousands of dollars of damage to your HVAC?'}
          image={require('../../assets/img/graphics/equipment.png')}
          link=''
          linkText='Learn More'
          left
        />
        {/* <GetStarted />
        <Testimonials /> */}
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

const styles = {};

export default Homepage;
