import React, { Component } from "react";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="box">
        <img src={require('../../../assets/img/people/dean.png')} height='90' alt className="m-auto" />
        <h3>Adil Elsaeed</h3>
        <span>Wordpress Developer</span>
        <p>
          Plan ahead by day, week, or month, and see project status at a glance.
          Search and filter to focus in on anything form a single what drives
          them to purchase.a.
        </p>
      </div>
    );
  }
}

const styles = {};

export default Testimonial;
