import React, { Component } from "react";
import colors from "../../assets/colors";

class ColorSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="xMinYMin meet"
        style={styles.svg}
      >
        <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" />
      </svg>
      <div style={styles.wrapper} />
      </React.Fragment>
    );
    return <div style={styles.wrapper} />;
  }
}

const styles = {
  wrapper: {
    height: 150,
    backgroundColor: colors.main,
    marginTop: -5
  },

  svg: {
    display: "inline-block",
    stroke: "none",
    fill: colors.main,
    transform: "rotate(180deg)",
    marginTop: -110
  },
  colorContainer: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    paddingBottom: "100%",
    verticalAlign: "middle",
    overflow: "hidden"
  }
};

export default ColorSection;
