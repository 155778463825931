import React, { Component } from "react";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="slider d-flex align-items-center" id="slider">
        <div className="container">
          <div className="content">
            <div className="row d-flex align-items-center">
              <div className="col-md-5">
                <div className="left">
                  <h1>
                    Multi Site
                    <br />
                    Facility Monitoring
                  </h1>
                  <p>
                    Do you operate or manage many facilities? AFM was built for
                    you. Our automated fault detection and diagnostics (AFDD)
                    give you an up to the minute health status report of your
                    facility portfolio.
                  </p>
                  {/* <a href="#0" class="btn-1">Get Started</a> */}
                  {/* <a href="#0" className="btn-2">
                    Learn More
                  </a> */}
                </div>
              </div>
              {/* Right*/}
              <div className="col-md-6 offset-md-1">
                <div className="right">
                  <img
                    src={require('../../assets/img/graphics/map.png')}
                    alt="slider-img"
                    className="img-fluid wow fadeInRight"
                    data-wow-offset={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = {};

export default Hero;
