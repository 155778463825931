import React, { Component } from 'react';
import Testimonial from './Testimonial';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <section className="testimonials">
  <div className="container text-center">
    <div className="heading">
      <h2>Testimonials</h2>
    </div>
    <div className="line" />
    <div className="slick-slider">
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
      <Testimonial />
    </div>
  </div>
</section>

    );
  }
}

const styles = {

};

export default Testimonials;