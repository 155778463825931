import React, { Component } from "react";

class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="get-started">
        <div className="container text-center">
          <span>get started</span>
          <h3>Let's build the perfect IoT based Facility Management System together</h3>
          <a href="#0" className="btn-1">
            Get Started
          </a>
        </div>
      </section>
    );
  }
}

const styles = {};

export default GetStarted;
