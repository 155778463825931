import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';

class ImageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { image, title, description, header, left, linkText, hideButton } = this.props;
    return (
      <section className="project">
        <div className="container">
          <div className="row d-flex align-items-center">
            {left ? (
              <div className="col-md-6">
                <img src={image} alt="project" className="img-fluid" />
              </div>
            ) : null}

            <div className="col-md-5">
              <div className="right" style={isMobile ? {marginTop: 10} : undefined}>
                {header ? <span>{header}</span> : null}
                <h2 class={isMobile ? 'text-center' : undefined}>{title}</h2>
                <p>{description}</p>
                {/* {hideButton ? null : 
                <Link to={'/info/test'} className="btn-1 shadow-sm">
                  {linkText}
                </Link>
                } */}
              </div>
            </div>

            {left ? null : (
              <div className="col-md-6">
                <img src={image} alt="project" className="img-fluid" />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const styles = {};

export default ImageSection;
