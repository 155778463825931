import React, { Component } from 'react';


class RFMAPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){

    window.location = 'https://register.afm.io';

  }

  render() {
    return(null);
  }
}

const styles = {

};

export default RFMAPage;