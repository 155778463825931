import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  demoClick = () => {
    document.getElementById('contact').scrollIntoView()
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm" style={{paddingTop: 8, paddingBottom: 8, backgroundColor: '#fff'}}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={require('../assets/img/logo.png')} alt="logo" height={45} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to='/' className="nav-link" href="#slider">
                  Home
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" href="#slider">
                  Blog
                </Link>
              </li> */}
            </ul>
            <div onClick={this.demoClick} className="btn-1" style={{cursor: 'pointer'}}>
              Request a Demo
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const styles = {};

export default Navbar;
