import React, { Component } from 'react';

// components
import Navbar from '../../components/Navbar';
import Hero from './Hero';
import Contact from '../Homepage/Contact';
import Footer from '../Homepage/Footer';
import ColorSection from './ColorSection';
import Page from './Page';
import ImageSection from '../Homepage/ImageSection';

class InfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <div>
        <Navbar />
        <div class='container' style={{paddingTop: 30}}>
          <ImageSection 
            title="Here's a Title About This Section, and Why It's Important"
            description={'We can detect urgent problems hours before building occupants do, and long after they\'ve gone home for the night. Reduce \
            your risk of facility downtime and be the hero to your occupants again and again. Get some sleep -  we have emergencies covered.'}
            image={require('../../assets/img/graphics/emergency_mgmt.png')}
            link=''
            linkText='Learn More'
            left
            hideButton
          />
        </div>
        <ColorSection />
        <Page />

        <Contact />
        <Footer />
      </div>
    );
  }
}

const styles = {

};

export default InfoPage;