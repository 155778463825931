import React, { Component } from "react";

class PrivacyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <h2 style={{ textAlign: "center", marginTop: 20 }}>
          Active Facility Monitoring End User License Agreement
        </h2>
        <p style={styles.middleText}>
          Active Facility Monitoring, LLC ("AFM") HAS DEVELOPED A SOFTWARE
          APPLICATION FOR MANAGING, MANIPULATING AND ANALYZING DATA COMMERCIALLY
          KNOWN AS “THE AFM HUB.” AFM IS WILLING TO PROVIDE A LIMITED LICENSE OF
          THE SOFTWARE THAT ACCOMPANIES THIS END USER LICENSE AGREEMENT TOGETHER
          WITH THE PRINTED OR ONLINE DOCUMENTATION FURNISHED BY AFM IN
          CONJUNCTION WITH IT TO YOU ONLY ON THE CONDITION THAT YOU ACCEPT ALL
          OF THE TERMS IN THIS AGREEMENT. PLEASE READ THE TERMS AND CONDITIONS
          OF THIS AGREEMENT CAREFULLY BEFORE CLICKING ON THE "I ACCEPT" BUTTON.
          BY ACCEPTING THE EULA AS PART OF THE INSTALLATION PROCESS YOU
          ACKNOWLEDGE THAT YOU HAVE READ AND ACCEPTED THIS AGREEMENT, AND AGREE
          TO BE BOUND BY ITS TERMS AND CONDITIONS. SUCH ACCEPTANCE IS EITHER ON
          YOUR OWN BEHALF, OR ON BEHALF OF ANY CORPORATE ENTITY WHICH EMPLOYS
          YOU OR WHICH YOU REPRESENT (‘CORPORATE LICENSEE’). IN THIS LICENSE
          AGREEMENT, “YOU” INCLUDES ANY CORPORATE LICENSEE. YOU AGREE THAT THIS
          AGREEMENT IS AS ENFORCEABLE AS ANY WRITTEN NEGOTIATED AGREEMENT
          MANUALLY SIGNED BY YOU AND THAT BY CLICKING ON THE “I AGREE” BUTTON
          BELOW OR ANY OTHER BUTTON EXPRESSING YOUR AGREEMENT TO THE TERMS
          HEREIN, YOU ARE PROVIDING YOUR ELECTRONIC SIGNATURE TO THIS AGREEMENT.
          CLICK ON THE "DO NOT ACCEPT" BUTTON TO DISCONTINUE THE INSTALLATION
          PROCESS. THE ACCEPTANCE OF THIS AGREEMENT IS REQUIRED FOR USE OF THE
          SOFTWARE.
          <br />
          <br />
          1. LICENSE GRANTS AND RESTRICTIONS.
          <br />
          <br />
          1.1 The rights, restrictions and obligations shall apply to all
          purchasers of THE AFM HUB software hereunder, regardless of the
          software product purchased (whether the standard THE AFM HUB end user
          software or the THE AFM HUB SaaS Authorization option applications
          (separately or collectively, as applicable, the “Software”), except as
          may be expressly provided in this Agreement.
          <br />
          <br />
          1.1.1 Standard THE AFM HUB End User License ONLY. If you are
          purchasing a standard THE AFM HUB end user license, then, subject to
          the terms and conditions contained herein, AFM hereby grants you a
          non-exclusive, limited license to install, use and execute a single
          copy of the Software on a single personal computer or server, which is
          identified by serial number or other means during the installation
          process (the "Designated System"), and to use the documentation
          furnished by AFM in conjunction with it, solely for your internal
          business use.
          <br />
          <br />
          1.1.2 THE AFM HUB SaaS Authorization Option. If you are purchasing the
          SaaS Authorization option, i.e., the right to use THE AFM HUB in a
          SaaS Mode (as defined below), then, subject to the terms and
          conditions contained herein, AFM hereby grants to you a non-exclusive,
          limited license to install, use and execute a single copy of the
          Software on the Designated System, and to use the documentation
          furnished by AFM in conjunction with it, for your internal business
          use and in connection with commercial purposes and uses, including
          without limitation providing services to your customers, such as
          hosting the copy of the Software on a server to offer “Software as a
          Service” services. For purposes of this Agreement, “SaaS Mode” shall
          mean the use of THE AFM HUB in a “Software as a Service” mode, in
          which you retain possession and control of your copy of the Software
          on the Designated System but have the ability to grant a third-party
          customer access to the Software to enable such customer to generate or
          deliver data, reports or other business value for such customer,
          whether through use of AFM’s specific software applications in the
          Software, or through use of your own proprietary software
          applications, views and reports created by you, but based upon the
          features and capabilities of the Software. You may not grant any
          rights to any customer to access or use the Software in any manner
          that is outside the scope of or not permitted under this Agreement and
          you shall remain solely responsible for, and liable to, AFM for your
          customers’ access and use of the Software.
          <br />
          <br />
          1.2 Restrictions. You may transfer and use the Software on a backup
          computer system you own or lease if, and only for so long as, the
          Designated System is (i) inoperative or (ii) unavailable due to
          regularly scheduled maintenance, upon issuance by AFM of a backup
          license key. For purposes of this Agreement, the “Software” shall be
          deemed to include the application program interfaces (“APIs”), and
          related source code, as well as source code for the purposes of
          creating, editing, producing or making rules, relevant databases or
          analytic libraries or similar applications for use in connection with
          the Software (the “Example Code”) and software owned by third parties
          (“Third Party Software”) that are provided to you along with the AFM
          THE AFM HUB software. You may create derivative works of the Example
          Code solely for the purposes set forth in this Section 1.1, and shall
          have the right to modify, enhance, adapt, or translate the Example
          Code for such purposes.
          <br />
          <br />
          1.3 No Other Rights Granted. Apart from the license expressly granted
          herein, no license or other right is granted by AFM to you under this
          Agreement, either directly or by implication, estoppel, or otherwise
          (including, but not limited to, the right to prepare derivative works
          of the Software). You shall have no right or access to the source code
          of the Software other than the source code, if any, for certain
          application program interfaces and Example Code that are included with
          the Software. All rights not specifically granted to you in this
          Agreement are reserved by AFM.
          <br />
          <br />
          1.4 Rights for Purchasers of THE AFM HUB SaaS Authorized Licenses
          Conditioned Upon Maintenance Agreement and Payments. If you are
          purchasing the SaaS Authorization option, then the rights granted to
          you, and AFM’s obligations, under this Agreement are conditioned upon
          (1) the prior execution of a separate maintenance agreement with
          respect to the Software (“Maintenance Agreement”) by both you and AFM
          or its designee; and (2) timely payment of annual maintenance fees
          (“Annual Maintenance Fees”) under the Maintenance Agreement. For the
          avoidance of doubt, failure to timely pay the Annual Maintenance Fees
          under the Maintenance Agreement shall be deemed a material breach of
          this Agreement, which may result in termination of this Agreement
          under Section 10.2 herein, regardless of whether such nonpayment is
          deemed a breach of the Maintenance Agreement.
          <br />
          <br />
          2. YOUR RESPONSIBILITIES.
          <br />
          EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, YOU SHALL (I) USE ALL
          COMMERCIALLY REASONABLE EFFORTS TO ENSURE THAT YOUR EMPLOYEES COMPLY
          WITH THE TERMS OF THIS AGREEMENT; (II) NOT MODIFY, ENHANCE, DECRYPT,
          EXTRACT, ADAPT, TRANSLATE, ALTER, DELETE, DISASSEMBLE, DECOMPILE OR
          OTHERWISE REVERSE ENGINEER THE SOFTWARE OR ANY PART THEREOF, OR ANY
          APPLICATION ENABLER OR GRANT ANY OTHER PERSON OR ENTITY THE RIGHT TO
          DO SO OR TAKE ANY ACTION THAT WOULD ASSIST ANY OTHER PERSON OR ENTITY
          IN DOING SO AND WILL PROMPTLY NOTIFY AFM OF ANY INFORMATION THAT ANY
          OTHER PERSON OR ENTITY IS OR IS ATTEMPTING TO COPY, REVERSE ENGINEER,
          DISASSEMBLE, DECOMPILE, TRANSLATE OR MODIFY THE SOFTWARE ; (III) NOT
          INSERT, DELETE, REPLACE, CHANGE OR OTHERWISE ALTER ANY FILES IN THE
          SOFTWARE OR APPLICATION ENABLER; (IV) NOT MODIFY, CHANGE, PREPARE
          DERIVATIVE WORKS OF OR OTHERWISE ALTER ANY BINARY CODE FILES INCLUDED
          WITH THE SOFTWARE; (V) NOT LOAN, RENT, LEASE, GIVE, SUBLICENSE,
          TRANSFER, PUBLISH, DISCLOSE, DISPLAY, PROVIDE ACCESS TO, OR OTHERWISE
          MAKE AVAILABLE THE SOFTWARE, IN WHOLE OR IN PART, TO ANY OTHER THIRD
          PARTY OR ENTITY, PROVIDED THAT, FOR PURCHASERS OF THE THE AFM HUB BASE
          LICENSE ONLY, YOU MAY PROVIDE ACCESS TO THE OBJECT CODE OF THE
          SOFTWARE TO YOUR CUSTOMERS (WITH NO RIGHT TO SUBLICENSE), AS LONG AS
          SUCH PERMITTED USAGE SHALL NOT OTHERWISE VIOLATE THE TERMS OF THIS
          AGREEMENT AND YOU SHALL BE SOLELY RESPONSIBLE FOR, AND INDEMNIFY,
          DEFEND AND HOLD AFM HARMLESS FROM, ANY SUCH USAGE; (VI) NOT MODIFY ANY
          APPLICATION PROGRAMMING INTERFACE, INCLUDING MODIFYING ANY APPLICATION
          PROGRAMMING INTERFACE BY CREATING ADDITIONAL CLASSES WITHIN ANY
          INTERFACE OR OTHERWISE CAUSING THE ADDITION TO OR MODIFICATION OF THE
          CLASSES IN AN INTERFACE, (VII) NOT INCORPORATE ANY CODE INCLUDED WITH
          THE SOFTWARE OR ANY DOCUMENTATION IN ANY DEVELOPER PRODUCT, AND (VIII)
          OBTAIN AND MAINTAIN, AT YOUR OWN EXPENSE, ALL COMPUTER HARDWARE,
          SOFTWARE, AND OTHER COMMUNICATION EQUIPMENT NECESSARY TO USE THE
          SOFTWARE.
          <br />
          <br />
          3. TECHNICAL SUPPORT, SOFTWARE MAINTENANCE AND TRAINING.
          <br />
          <br />
          3.1 Neither AFM nor any of its affiliates, distributors or resellers
          shall have any obligation to provide technical support, training or
          software maintenance services to you in connection with the Software,
          except as may be provided under a separate agreement between any such
          party and you.
          <br />
          <br />
          3.2 If you are purchasing a standard THE AFM HUB end user license,
          then you may purchase maintenance services for the Software from AFM
          or its resellers under a separate Maintenance Agreement.
          <br />
          <br />
          3.3 Any maintenance services may include providing you with regular
          updates, software corrections, bug fixes, and new functionality to the
          Software, which may include service packs to correct defects and add
          functionality (collectively, “Updates”). Any such Updates provided to
          you are and shall remain the exclusive property of AFM. Such Updates
          shall be considered Software, and subject to the terms and conditions
          in this Agreement.
          <br />
          <br />
          4. FEES.
          <br />
          <br />
          The license fees paid by you to AFM or its designee are paid in
          consideration of the rights granted under this Agreement.
          <br />
          <br />
          4.1 Restrictions on Points-Based License Fees. The license fee charged
          to you in connection with your use of the Software is based upon the
          capacity of the Software as measured by "Points" (“Points-Based
          License Fee”). A Point models a sensor, actuator, or setpoint or any
          similar item for which you want to collect data. Any database record
          with historical trend data associated with it, or that utilizes the
          Software’s Connector Framework to read or write data values, is
          considered a “Point” for purposes of this Agreement.
          <br />
          <br />
          4.1.1 Accessing Data Without Creating Points. It is specifically
          prohibited to utilize the Software’s functionality to read or write
          data values without creating database records for the Points
          associated with the external data source, thereby bypassing the
          Points-based Licensing mechanism.
          <br />
          <br />
          4.1.2 Deleting Points to Regain License Capacity. As a licensed user,
          the Software allows you to delete Points thereby regaining capacity as
          measured by the Points- Based License Fee. The ability to delete
          Points to regain capacity is specifically authorized for use where
          Points are no longer being used with the Software. The capacity
          regained may be used with new Points (up to the limit of Points
          authorized under your license), but not for Points that you have
          previously created and for which you received beneficial use and then
          deleted. Should you have questions on the authorized use of your
          ability to delete Points to regain license capacity, please contact
          AFM at info@AFM.com
          <br />
          <br />
          5. OWNERSHIP AND CONFIDENTIALITY.
          <br />
          <br />
          5.1 Ownership by AFM. AFM retains all right, title and interest in and
          to the Software (and any portions thereof), other than Third Party
          Software. You acknowledge that the Software uses trademarks,
          copyrights, trade secrets and other proprietary material the rights to
          which are owned or licensed by AFM, its affiliates, third party
          licensors or suppliers, and that such proprietary rights are protected
          by law, including U.S. copyright laws, patent laws and international
          treaty provisions. You shall not have or obtain any right, title, or
          interest to the Software except as provided in this Agreement, and
          further shall secure and protect the Software consistent with the
          maintenance of AFM's proprietary rights therein. You agree that you
          shall not contest or challenge, or take any action inconsistent with
          or that may damage or impair AFM's ownership or rights in and to the
          Software, and further that you shall not contest or challenge, or take
          any action inconsistent with, or that may damage or impair the
          ownership or intellectual property rights of, AFM in the Software, or
          of AFM's licensors with respect to the Third Party Software. You shall
          not use the Software except as is expressly authorized in this
          Agreement.
          <br />
          <br />
          5.2 Confidentiality. You acknowledge that the Software contains
          valuable trade secrets of AFM and you agree to use your best efforts
          to maintain the confidentiality of the Software using at least the
          same degree of care that you use with your own confidential
          information, but no less than reasonable care. Notwithstanding any
          provision of this Agreement to the contrary, this Section 5.2 shall
          not supercede any separate confidentiality or nondisclosure agreement
          you may have entered into with AFM related to the Software.
          <br />
          <br />
          5.2.1 Compliance with Data Protection Laws. If you have purchased the
          THE AFM HUB SaaS Authorization option, you agree that you shall comply
          with all applicable privacy and data protection laws that may apply to
          you or your customers to whom you grant access to the Software,
          including without limitation federal and state data privacy, breach
          notification and security laws as well as the laws of any other
          country that may apply to you or your customers, including without
          limitation the General Data Protection Regulation 2016/679 of the
          European Union.
          <br />
          <br />
          5.3 Third-Party Code. Additional copyright notices and license terms
          applicable to portions of the Software are set forth in the
          readmeLicenses.txt file provided with the Software. In addition to any
          terms and conditions of any third-party open source/freeware license
          identified in the readmeLicenses.txt file, the disclaimer of warranty
          and limitation of liability provisions contained below in paragraph 6
          of this Agreement shall apply to all third party code or software
          contained in the Software.
          <br />
          <br />
          6. WARRANTIES AND LIMITATIONS.
          <br />
          <br />
          6.1 Disclaimer of Software Warranty. THE SOFTWARE IS LICENSED “AS IS”
          AND YOU RECEIVE NO ADDITIONAL EXPRESS OR IMPLIED WARRANTIES. AFM AND
          ITS SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL OTHER WARRANTIES OF ANY
          KIND OR NATURE CONCERNING THE SOFTWARE, WHETHER EXPRESS, IMPLIED OR
          STATUTORY, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF TITLE,
          MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
          NON-INFRINGEMENT OR THE RESULTS TO BE OBTAINED FROM USE, TO THE
          FULLEST EXTENT ALLOWED BY APPLICABLE LAW. AFM EXPRESSLY DISCLAIMS ANY
          WARRANTIES THAT MAY BE IMPLIED FROM USAGE OF TRADE, COURSE OF DEALING,
          OR COURSE OF PERFORMANCE. FURTHER, WITHOUT LIMITING THE GENERALITY OF
          THE FOREGOING, AFM MAKES NO WARRANTIES OR REPRESENTATIONS AS TO
          PERFORMANCE OF THE SOFTWARE, AND SPECIFICALLY DISCLAIMS ANY WARRANTY
          THAT (1) THE SOFTWARE WILL OPERATE IN COMBINATION WITH OTHER ITEMS,
          EQUIPMENT, SOFTWARE, SYSTEMS OR DATA EXCEPT, (2) THE OPERATION OF THE
          SOFTWARE WILL BE UNINTERRUPTED OR ERROR FREE, OR (3) ERRORS IN THE
          SOFTWARE, IF ANY, WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR
          ADVICE GIVEN BY AFM, OR ITS AGENTS OR EMPLOYEES SHALL CREATE OR FORM
          THE BASIS OF ANY WARRANTY OF ANY KIND. THE SOFTWARE IS PROVIDED WITH
          ALL FAULTS AND THE ENTIRE RISK OF SATISFACTORY QUALITY, PERFORMANCE,
          ACCURACY, AND EFFORT IS WITH YOU.
          <br />
          <br />
          6.2 Limitation of Liability. IN NO EVENT SHALL AFM, ITS AFFILIATES,
          SUPPLIERS OR THIRD PARTY LICENSORS, OR THEIR RESPECTIVE MEMBERS,
          OFFICERS, DIRECTORS, SHAREHOLDERS, AGENT, EMPLOYEES, REPRESENTATIVES,
          SUCCESSORS AND ASSIGNS BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES OR PENALTIES (INCLUDING DAMAGES FOR
          LOST PROFITS, LOST BUSINESS, LOST DATA, BUSINESS INTERRUPTION, AND THE
          LIKE), HOWEVER IT ARISES, INCLUDING, BUT NOT LIMITED TO, THE USE BY
          YOU OF THE SOFTWARE, WHETHER FOR BREACH OF CONTRACT OR IN TORT, EVEN
          IF AFM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          <br />
          <br />
          6.3 Limitation of Remedies. NOTWITHSTANDING ANY TERM OR PROVISION TO
          THE CONTRARY IN THIS AGREEMENT, IN NO EVENT SHALL AFM'S MONETARY
          LIABILITY IN CONNECTION WITH THIS AGREEMENT OR THE SOFTWARE, TO YOU
          EXCEED $100.00.
          <br />
          <br />
          7. ASSURANCES BY YOU.
          <br />
          <br />
          There can be no assurances whatsoever that control systems such as the
          Software will protect any individual or his or her property from harm.
          Appropriate safety precautions must always be taken when reacting to
          information generated by software in connection with the operation or
          maintenance of equipment connected to the Software. AFM ASSUMES NO
          RESPONSIBILITY OR LIABILITY FOR ANY INJURY OR DAMAGE TO ANY PERSONS OR
          PROPERTY RESULTING FROM THE USE BY YOU OF THE SOFTWARE. Further, you
          represent and warrant that you will take appropriate precautions,
          establish appropriate procedures and post appropriate notices to
          ensure that persons and property are not harmed in the event of an
          error, malfunction or unexpected operation of the Software.
          <br />
          <br />
          8. HIGH RISK APPLICATIONS.
          <br />
          <br />
          You will make reasonable business efforts to ensure that the Software
          is not used in any application in which the failure of the Software
          could lead to death, personal injury or severe physical or property
          damage, including, without limitation, environmental damage,
          (collectively, "High-Risk Applications"), including but not limited to
          the operation of nuclear facilities, mass transit systems, aircraft
          navigation or aircraft communication systems, air traffic control,
          weapon systems and direct life support machines, unless otherwise
          permitted by AFM in writing. Without limiting any disclaimer of any
          express or implied warranty under this Agreement, AFM expressly
          disclaims any express or implied warranty or condition of fitness of
          the Software for High-Risk Applications.
          <br />
          <br />
          9. INDEMNIFICATION.
          <br />
          <br />
          You shall indemnify and hold harmless AFM, its affiliates, suppliers
          or third party licensors, or their respective members, officers,
          directors, shareholders, agent, employees, representatives, successors
          and assigns, from and against all losses, claims, damages or other
          causes of any nature or kind whatsoever (including reasonable
          attorney's fees) arising directly or indirectly out of third party
          claims concerning (i) a breach of any of your obligations, covenants,
          representations or warranties contained herein (including, without
          limitation if you are purchasing the THE AFM HUB SaaS Authorization
          option, any breaches caused by your customers); (ii) your selection
          of, transactions and/or agreements with any party that is a AFM
          reseller or distributor, systems integrator or trainer, or any other
          third party; (iii) any derivative work or product developed by you
          that is based on the APIs or Example Code, as permitted under this
          Agreement; (iv) any combination of the Software, or any component of
          it, with any product, equipment, device, software, hardware, system,
          data or other technology not supplied by AFM, (v) the negligence or
          intentional misconduct of you or your officers, employees, agents,
          contractors or customers, or (vi) any property or economic damages or
          personal injury or death arising from or related to the use of the
          Software. You agree not to interpose any cross-claim, third party
          claim or similar claim against AFM based on a claim, suit, action or
          proceeding threatened or commenced against you related to the Software
          and arising out of any matter other than a matter, if any, for which
          AFM has agreed to indemnify you under a separate agreement signed by
          AFM.
          <br />
          <br />
          10. TERM AND TERMINATION.
          <br />
          <br />
          10.1 Term. This Agreement is effective upon your clicking the "I
          Agree" button, or other button expressing your agreement to the terms
          herein, and shall continue until terminated.
          <br />
          <br />
          10.2 Termination. You may terminate this Agreement at any time by
          deactivating the Software or by providing written notice of
          termination to AFM and returning the Software and all copies and
          extracts to AFM. AFM may terminate this Agreement upon a material or
          continuing breach of this Agreement by you by the giving of 30 days
          prior written notice of termination, stating the cause therefore, with
          termination becoming effective at the close of said 30-day term if the
          breach is not then cured to the satisfaction of AFM.
          <br />
          <br />
          10.3 No Refunds. Notwithstanding the reason for termination of this
          Agreement by you or AFM, under no circumstances will AFM be obligated
          to provide any refunds of any portion of any amounts paid by you in
          connection with this Agreement or the Software.
          <br />
          <br />
          10.4 Survival. All provisions of this Agreement that by their nature
          are intended to survive the termination of this Agreement shall so
          survive.
          <br />
          <br />
          11. TRADEMARKS AND PROPRIETARY NOTICES.
          <br />
          <br />
          11.1 For Purchasers of the Standard THE AFM HUB End User License. If
          you are purchasing a standard THE AFM HUB end user license, then under
          no circumstances may you use any trademark or service mark of AFM to
          identify, or otherwise in connection with, goods or services provided
          by you except as expressly agreed to by AFM in writing.
          <br />
          <br />
          11.2 For Purchasers of the THE AFM HUB SaaS Authorization Option. If
          you are purchasing the THE AFM HUB SaaS Authorization option, then AFM
          hereby grants to you a non- exclusive, nontransferable right to use
          the registered or unregistered trademarks, service marks, trade names,
          logos, or other similar items of AFM (the “AFM Trademarks”) that AFM
          may communicate to you from time to time solely in connection with the
          marketing, advertising, and promotion of the Software as it may be
          used in the services provided to customers by you during the term of
          this Agreement. You agree to use the AFM Trademarks only in the form
          and manner as prescribed from time to time by AFM (including without
          limitation in any trademark usage and quality control guidelines), and
          will not use any other trademark or service mark in combination with
          the AFM Trademarks without the prior consent of AFM. You agree and
          acknowledges that the services provided under the AFM Trademarks shall
          be of a quality that is consistent with the quality of such services
          as provided by AFM and that AFM has the right to monitor and control
          the quality of such services provided by you. You agree that AFM is
          the sole and exclusive owner of the AFM Trademarks and you shall not
          use any AFM Trademarks in any business, corporate or trade name and
          shall not directly or indirectly attempt to adopt, use or register any
          mark that is confusingly similar to the AFM Trademarks, or contest or
          deny the validity or enforceability of the AFM Trademarks, or
          otherwise take any action that could be detrimental to the goodwill
          associated with the AFM Trademarks. Any and all goodwill arising from
          your use of the AFM Trademarks shall inure solely to the benefit of
          AFM.
          <br />
          <br />
          11.2.1 Promotion of the Software. You shall not produce or disseminate
          any marketing materials that state that you are the manufacturer of
          the Software or that you have any ownership rights in the Software. In
          addition, in any written materials supporting any advertising,
          mailing, or other promotions undertaken by you, you shall prominently
          display a notice of AFM’s ownership of the AFM Trademarks.
          <br />
          <br />
          11.2.2 Marketing Materials. You shall be solely responsible for
          developing marketing materials, subject to AFM's prior written
          approval, and for bearing all of its own costs of advertising and
          promoting the Software. Prior to the first distribution of any
          marketing materials, you shall provide to AFM, and obtain AFM's
          approval of, copies of all proposed marketing materials, including but
          not limited to, all print material and web site content. AFM shall not
          unreasonably withhold such approval and shall grant or deny approval
          of such marketing materials within five (5) Business Days after
          receipt of such marketing materials from you. Failure by AFM to
          respond to you within five (5) Business Days after receipt of such
          marketing materials from you for approval shall constitute approval of
          such marketing materials.
          <br />
          <br />
          11.2.3 Your Trademarks. Subject to AFM’s right of approval of
          marketing materials using the AFM Trademarks, you may place your
          trademarks on such marketing materials. you shall obtain such approval
          prior to the first sale of any Software and thereafter when proposing
          to make any material changes to the marketing materials previously
          approved under this Section 11.2.
          <br />
          <br />
          11.2.4 Actions upon Termination. Upon Termination of this Agreement
          for any reason, you shall immediately cease all use of the AFM
          Trademarks and shall destroy or return to AFM any and all marketing
          materials bearing the AFM Trademarks.
          <br />
          <br />
          11.3 Proprietary Notices. You acknowledge that the Software may
          contain certain proprietary notices (included but not limited to
          trademark and copyright notices), trademarks, service marks, and logos
          of AFM, its affiliates, suppliers, third party licensors and other
          third parties, and you agree not to remove, modify (including adding
          to), minimize, obscure or block such notices, trademarks, service
          marks or logos.
          <br />
          <br />
          12. GENERAL TERMS.
          <br />
          <br />
          12.1 Assignment. You may assign this Agreement or your rights and
          obligations under this Agreement to a purchaser of the hardware on
          which the Software is installed, provided (i) you provide such
          purchaser with a copy of this Agreement and (ii) the purchaser agrees
          in writing to comply with all of the terms and conditions of this
          Agreement. AFM may assign this Agreement without your consent.
          <br />
          <br />
          12.2 Export. Software, including technical data, is subject to U.S.
          export control laws, including the U.S. Export Administration Act and
          its associated regulations, and may be subject to export or import
          regulations in other countries. You agree to comply strictly with all
          such regulations and acknowledge that you have the responsibility to
          obtain such licenses to export, re-export, or import the Software. The
          Software may not be used, sold, resold, sublicensed, diverted,
          transferred, reshipped, or otherwise exported or re- exported: (i) in,
          into or through any country designated as a terrorist supporting
          country by the U.S. government or any of its agencies; (ii) in, into
          or through any country for which the U.S. has an embargo or with which
          the U.S. or any of its agencies maintains comprehensive trade
          controls; (iii) to or by a national or resident of the countries
          described in (i) or (ii); or (iv) to or by any party included in the
          United States Department of Commerce's Denied Persons List, Entity
          List or Unverified List; or the United States Department of the
          Treasury's Specially Designated Nationals, Specially Designated Global
          Terrorists, Specially Designated Narcotics Traffickers, Specially
          Designated Narcotic Traffickers, or Specially Designated Terrorists
          List; or the United States Department of State's Designated Foreign
          Terrorist Organizations or Debarred Persons List; or is otherwise
          designated by the U.S. government or any of its agencies as a party
          with which it is unlawful to do business.
          <br />
          <br />
          12.3 Equitable Relief. You acknowledge that any breach of your
          obligations hereunder with respect to the Software or the confidential
          information of AFM, including, without limitation, Section 5.1 and 5.2
          above, will cause AFM irreparable injury for which it has no adequate
          remedy at law. You further agree that AFM will be entitled to seek and
          obtain equitable relief, including preliminary injunctions and
          temporary restraining orders, to prevent any unauthorized use of the
          Software and confidential information, without posting of bond or
          other security, in addition to all other remedies available to it
          under this Agreement or other applicable law.
          <br />
          <br />
          12.4 Entire Agreement. Except with respect to any separate
          confidentiality agreements that you may have signed with AFM relating
          to the Software, this Agreement comprises the entire agreement between
          the parties relating to its subject matter and this Agreement
          supersedes all prior agreements and understandings, written or oral,
          express or implied. This Agreement can be amended or modified only by
          a writing executed in advance by duly authorized representatives of
          each of the parties hereto. In the event any foreign ministry or other
          governmental entity or agency makes any changes, deletions or
          modifications to this Agreement, holds any provision herein
          unenforceable or imposes any conditions or restrictions on either
          party to this Agreement which affects its ability to fully perform,
          AFM shall have the right to immediately terminate this Agreement. You
          represent and warrant that all consents, approval or authorizations of
          third parties, foreign ministries or any governmental entities or
          agencies, required as a condition or otherwise necessary for you to
          enter into and perform its obligations under this Agreement have been
          duly obtained.
          <br />
          <br />
          12.5 Notices. Any notice or other communication required or permitted
          under this Agreement shall be in writing and shall be delivered by
          hand, by registered prepaid first class mail, by facsimile or by
          electronic mail to the persons or addresses specified on the signature
          page of this Agreement (or as otherwise may have been communicated in
          writing). A notice or other communication shall be deemed to have been
          served and received (a) on the date delivered, if delivered by hand or
          sent by registered prepaid first class mail, or (b) upon
          acknowledgement of receipt, if sent by facsimile or electronic mail.
          <br />
          <br />
          12.6 Waiver. A waiver of any breach of default of this Agreement shall
          not create a waiver of the term or of any subsequent breach of
          default.
          <br />
          <br />
          12.7 Governing Law; Choice of Venue. The validity of this Agreement
          and the rights, obligations and relations of the parties hereunder
          shall be construed and determined under and in accordance with the
          substantive laws of the State of Florida with respect to claims
          governed by state law and the laws of the United States with respect
          to claims arising under the laws of the United States, without regard
          to conflicts of laws principles and excluding the Convention on
          Contracts for the International Sale of Goods. Any action arising from
          or relating to this Agreement or the conduct of the parties pursuant
          hereto shall be commenced and heard solely within a federal or state
          court of competent jurisdiction found within the boundaries of the
          United States District Court for the State of Florida, and AFM and you
          each consent to personal jurisdiction and venue in any such court.
          <br />
          <br />
          12.8 Severability. Should any term or provision of this Agreement be
          finally determined by a court of competent jurisdiction to be void,
          invalid, unenforceable or contrary to law or equity, the offending
          term shall be modified and limited (or if strictly necessary, deleted)
          only to the extent required to conform to the requirements of law and
          the remainder of this Agreement (or, as the case may be, the
          application of such provisions to other circumstances) shall not be
          affected thereby but rather shall be enforced to the greatest extent
          permitted by law.
          <br />
          <br />
          12.9 Government Use. The Software is provided with restricted rights.
          With respect to any acquisition of the Software by or for any unit or
          agency of the U.S. Government ("Government"), the Software shall be
          classified as "Commercial Computer Software," as that term is defined
          in the applicable provisions of the Federal Acquisition Regulation
          ("FAR") and supplements thereto, including the Department of Defense
          (“DoD”) FAR Supplement ("DFARS"). If the Software is supplied for use
          by DoD, the Software is delivered subject to the terms of this
          Agreement and either (i) in accordance with DFARS 252.227-7202-1(a)
          and 227.7202-3(a), or (ii) with restricted rights in accordance with
          DFARS 252.227 7013(c), as applicable. If the Software is supplied for
          use by a federal agency other than DoD, the Software is restricted
          computer software delivered subject to the terms of this Agreement and
          (i) FAR 12.212(a); (ii) FAR 52.227-19; or (iii) FAR 52.227-14 (ALT
          III), as applicable.
          <br />
          <br />
          12.10 Binding on Successors. This Agreement shall be binding upon you,
          your successors and assignees.
          <br />
          <br />
        </p>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    whiteSpace: "pre-wrap",
    margin: "auto",
    maxWidth: 700,
    padding: 10
  }
};

export default PrivacyPage;
